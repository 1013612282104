<template>
  <section class="dtc-main-section12 doc6 mt-1" id="">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件4-2
    </header>
    <header
      class="mb-2"
      style="font-size:20px;display:grid;place-items:center;font-family:DFKai-sb;"
    >
      {{ schoolName }}學校{{ year }}學年度{{
        term == 1 ? "上" : "下"
      }}學期偏鄉護理菁英公費生學生輔導費印領清冊核銷明細表
    </header>

    <header class="grid-5-dtc">
      <div>年級</div>
      <div>輔導學生姓名</div>
      <div>輔導老師姓名</div>
      <div>申請金額</div>
      <div>輔導老師具領人簽章</div>
    </header>

    <main
      class="grid-5-dtc"
      v-for="item in studentfee"
      :key="item"
      style="margin:0px;border-top:0px;"
    >
      <div>{{ item.Grade }}</div>
      <div>{{ item.StudentName }}</div>
      <div>{{ item.TeacherName }}</div>
      <div class="text-right-mode">${{ $formatPrice(item.Fee) }}</div>
      <div></div>
    </main>

    <main class="grid-5-dtc" style="margin:0px;border-top:0px;">
      <div>合計</div>
      <div></div>
      <div></div>
      <div class="text-right-mode">${{ $formatPrice(amount) }}</div>
      <div class="last-one">
        <div></div>
      </div>
    </main>

    <footer class="dtc-note mt-3">
      <div>備註</div>
      <div>
        1.每位學生每學期每輔導1次補助1000元，並須以面談形式進行輔導，以及個別填寫個別輔導紀錄及總表。
      </div>
      <div>
        2.期末核銷除填寫印領清冊明細表外，需檢附個別輔導紀錄表及總輔導紀錄表(均為正本)，以俾利審核。
      </div>
    </footer>

    <foooter class="mt-3 mb-4 grid-footer-4">
      <div>註冊組</div>
      <div>出納組</div>
      <div>會計(財務)</div>
      <div>校長</div>
    </foooter>
  </section>
</template>

<script>
import queryString from "query-string";
const names = ["項目名稱", "數量", "單價", "總價", "備註說明"];

export default {
  name: "totalSpend14",
  data() {
    return {
      names,
      schoolName: "",
      year: "",
      studentfee: [],
      map: {},
      amount: 0,
      term: "",
    };
  },
  async mounted() {
    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }
    //check Can see and then get data
    const {
      schoolcode: SchoolCode = "",
      byear: Byear = "108",
      term = 1,
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = term; // == 1 ? '上' : '下';
    this.year = Byear;
    let schoolArr = await window.axios.get("School/GetAll");
    this.schoolName = schoolArr.find(
      (s) => s.SchoolCode == SchoolCode
    ).SchoolName;
    try {
      this.map = await axios.get(
        `RecordAllWoff/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
      );
      if (
        !Boolean(this.map.length) ||
        (Boolean(this.map.length) &&
          !Boolean(this.map[0].nurse_recordall_woff.length))
      ) {
        this.studentfee = [];
        this.$bvToast.toast(`請填寫完畢後再執行列印`, {
          title: "學生輔導費尚無資料",
          autoHideDelay: 5000,
          variant: "danger",
        });
      } else {
        this.studentfee = this.map[0].nurse_recordall_woff;
      }

      if (this.studentfee.length) {
        this.studentfee.forEach((element) => {
          this.amount = this.amount + element.Fee;
        });
      }
    } catch (e) {
      this.$bvToast.toast(e + ``, {
        title: "學生輔導費",
        autoHideDelay: 5000,
        variant: "danger",
      });
    }
  },
};
</script>

<style lang="scss">
.dtc-main-section12 {
  margin: 0 auto;
  width: 1140px;
}
// }
.dtc-note {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: 108px;
  grid-template-columns: 1fr;
  padding: 7px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}
.grid-5-dtc {
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 260px 160px 160px 120px 1fr;
  border: 1px solid black;
  min-height: 56px;
  > div {
    // height: 40px;
    font-size: 16px;
    text-align: center;
    border-right: 1px solid black;
    padding-top: 10px;
    //line-height: 100px;
  }
  > div:last-child {
    border-right: none;
  }
}
.grid-6-dtc2 {
  margin-top: 0px;
  border-top: none;
  > div {
    height: 400px;
    padding-top: 0px;
  }
  > div:first-child {
    display: grid;
    grid-template-columns: 30px 1fr;
    nav:first-child {
      padding-top: 0px;
      writing-mode: vertical-lr;
      font-size: 20px;
      border-right: 1px solid black;
    }
  }
}

.sub-3-row {
  display: grid;
  grid-template-rows: 70px 1fr 40px;
  > div {
    border-bottom: 1px solid black;
  }
  > div:last-child {
    border-bottom: none;
  }
}
.grid-footer-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 10px;
  font-size: 24px;
  padding-left: 2rem;
  margin-bottom: 4rem;
}

.last-one {
  display: grid;
  place-items: center;
  > div {
    width: 100%;
    height: 1px;
    background: black;
    transform: rotate(6deg);
  }
}
.text-right-mode {
  text-align: right !important;
  padding-right: 5px;
}
@media print {
  @page {
    size: A4 landscape;
  }
}
</style>
